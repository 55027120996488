<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/finance_2/banner.png" alt="" />
    </div>
    <div class="container">
      <div class="box">
        <div class="box_title">满意度评价</div>
        <div class="rate_list">
          <div class="rate_item">
            <span class="demonstration">
              您认为本平台的操作简易程度（1-10分）
            </span>
            <el-rate
              v-model="value1"
              show-score
              text-color="#ff9900"
              :max="10"
              score-template="{value}"
            ></el-rate>
          </div>

          <div class="rate_item">
            <span class="demonstration">
              您认为本平台提供的相关服务是否完善（1-10分）
            </span>
            <el-rate
              v-model="value2"
              show-score
              text-color="#ff9900"
              :max="10"
              score-template="{value}"
            ></el-rate>
          </div>
          <div class="rate_item">
            <span class="demonstration">
              您是否能够及时联系到工作人员（1-10分）
            </span>
            <el-rate
              v-model="value3"
              show-score
              text-color="#ff9900"
              :max="10"
              score-template="{value}"
            ></el-rate>
          </div>

          <div class="rate_item">
            <span class="demonstration">
              工作人员为您提供的方案是否能解决您的问题（1-10分）
            </span>
            <el-rate
              v-model="value4"
              show-score
              text-color="#ff9900"
              :max="10"
              score-template="{value}"
            ></el-rate>
          </div>

          <div class="rate_item">
            <span class="demonstration"> 您对本平台是否满意（1-10分） </span>
            <el-rate
              v-model="value5"
              show-score
              text-color="#ff9900"
              :max="10"
              score-template="{value}"
            ></el-rate>
          </div>
        </div>
        <div class="suggest">
          <div class="suggest_title">
            您对正在使用的服务平台产品还有哪些意见和建议以及功能需求，欢迎告诉我们：
          </div>
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入意见和建议以及功能需求"
            v-model="textarea"
          >
          </el-input>
        </div>
        <div class="btn" @click="sub">提交</div>
      </div>
    </div>


    <el-dialog :visible.sync="dialogSuccess" width="25%" center>
      <div class="success_icon">
        <img src="../../assets/img/success.png" alt="" />
      </div>
      <div class="success_title">提交成功</div>
      <div class="success_content">
        <p>感谢您的评价</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addSatisfactionInfo } from "@/api/reserve";

export default {
  components: {},
  props: {},
  data() {
    return {
      dialogSuccess: false,
      value1: null,
      value2: null,
      value3: null,
      value4: null,
      value5: null,
      textarea: "",
    };
  },
  watch: {},
  computed: {},
  methods: {
    sub() {
      let data = {
        satisfactionA: this.value1,
        satisfactionB: this.value2,
        satisfactionC: this.value3,
        satisfactionD: this.value4,
        satisfactionE: this.value5,
        remark: this.textarea,
      };
      addSatisfactionInfo(data).then((response) => {
        this.dialogSuccess = true;
      });
      
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.wrapper {
}

.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  padding: 40px;
}

.box {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 60px;
}

.box_title {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.title {
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.rate_list {
  margin-top: 40px;
  padding: 0 100px;
}

.rate_item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  text-align: center;
}

.demonstration {
  display: block;
  font-size: 18px;
  margin-bottom: 10px;
}

.suggest {
  padding: 0 100px;
}

.suggest_title {
  font-size: 18px;
  margin-bottom: 20px;
}

.suggest .el-textarea {
  width: 1000px;
}

.btn {
  width: 200px;
  font-size: 14px;
  text-align: center;
  padding: 10px 40px;
  border-radius: 25px;
  color: #fff;
  background: #055afe;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 70px;
}

/* 成功弹窗 */
.success_icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.success_icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.success_title {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin: 30px 0;
}

.success_content {
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: #919191;
}
.success_content p span {
  color: #055AFE;
}
</style>
